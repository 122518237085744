<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">Créer un compte</h1>
				<p class="text-lg">Formulaire de création utilisateur</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
			<template #title>
				<h5 class="font-semibold text-center">Register With</h5>
			</template>
			<div class="sign-up-gateways">
    			<a-button>
					<img src="images/logos/logos-facebook.svg" alt="">
				</a-button>
    			<a-button>
					<img src="images/logos/logo-apple.svg" alt="">
				</a-button>
    			<a-button>
					<img src="images/logos/Google__G__Logo.svg.png" alt="">
				</a-button>
			</div>
			<p class="text-center my-25 font-semibold text-muted">Or</p>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'name',
						{ rules: [{ required: true, message: 'Entrez votre Prénom' }] },
						]"
						placeholder="Name"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]"
						placeholder="Email"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your Password!' }] },
						]"
						type="password"
						placeholder="Password"
					>
					</a-input>
				</a-form-item>
        <a-form-item class="mb-5">
          <a-input
              v-decorator="[
						'Password_confirmation',
						{ rules: [{ required: true, message: 'Confirmez votre mot de passe' }] },
						]"
              type="password"
              placeholder="Password confirmation"
          >
          </a-input>
        </a-form-item>
				<a-form-item class="mb-10">
					<a-checkbox
						v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						},
						]"
					>
						I agree the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
					</a-checkbox>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						SIGN UP
					</a-button>
				</a-form-item>
			</a-form>
			<p class="font-semibold text-muted text-center">Already have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign In</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
const axios = require('axios')
const connect = require('../config_developpemenrt')
const instance = axios.create({
  baseURL: `${connect.baseUri}`
})
	export default ({
		data() {
			return {
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
            console.log('Received values of form: ', values)
            instance.post(`${connect.baseUri}/register-user/`,{nom: values.name, email:values.email, image_name:'profile',user_privileges:'usr', password:values.password})
                .then((response)=> {
                  console.log(response.data)
                  let user_privileges = response.data.user_privileges
                  localStorage.setItem('user', JSON.stringify(response.data.user))
                  localStorage.setItem('jwt', response.data.token)
                  if (localStorage.getItem('jwt') != null) {
                    this.$emit('loggedIn')
                    console.log('loggedIn!!!!')
                    if (this.$route.params.nextUrl != null) {
                      this.$router.push(this.$route.params.nextUrl)
                      console.log('to next url')
                    }
                  }
                  else {
                    if (response.data.user.user_privileges === 'adm') {
                      console.log('is admin!!!')
                      this.$router.push('Tables')
                    } else {
                      this.$router.push('dashboard')
                    }
                  }
                }).catch(err=>console.log(err))
					}
				});
			},
		},
	})

</script>

<style lang="scss">
</style>